<template>
  <div class="company px-2 py-2">
    <div class="row mb-3 justify--space-between">
      <div class="flex align-self--center">
        <va-breadcrumbs class="mb-3">
          <va-breadcrumbs-item label="项目跟进记录" />
          <va-breadcrumbs-item label="添加/修改" />
        </va-breadcrumbs>
      </div>
      <div class="justify-self--end">
        <va-button :rounded="false" size="small" class="px-2" @click="returnToList" outline flat>返回</va-button>
      </div>
    </div>
    <va-form ref="recordForm" class="form" @validation="false">
      <div class="row" ref="dragUploadContainer">
        <div class="flex md6 xs12">
          <va-select label="公司" outline :rules="[value => (value && value.length > 0) || '必填']" 
            :requiredMark="true"
            :options="companyOptions" 
            @update-search="searchCompany"
            v-model="record.companyId"
            clearable
            :text-by="option => option.key" :value-by="option => option.value"
            searchable no-options-text="暂无结果" placeholder="请输入公司名查找" :loading="companyInSerach">
            <template #appendInner>
              <va-icon name="corporate_fare"/>
            </template>
          </va-select>
        </div>
        <div class="flex md6 xs12">
          <va-date-input label="沟通时间" :requiredMark="true" :disabled="formReadonly" v-model="record.communicateOn"  clearable :format="formatDate" mode="single" outline :rules="[value => value || '必填']" />
        </div>
        <div class="flex md6 xs12">
          <va-select label="沟通方式" :disabled="formReadonly" v-model="record.communicationType"  outline  :options="selectOptions.communicationType" :text-by="option => option.key" :value-by="option => option.value" clearable />
        </div>
        <div class="flex md6 xs12">
          <va-input label="牵头部门" v-model="record.headDepartment" :disabled="formReadonly"  outline />
        </div>
        <div class="flex md6 xs12">
          <va-input label="配合部门" v-model="record.cooperationDepartment" :disabled="formReadonly"  outline />
        </div>
        <div class="flex md6 xs12">
          <va-select label="具体负责人" outline
            :options="ownerOptions" 
            @update-search="searchOwners"
            v-model="record.ownersForm"
            clearable multiple
            :text-by="option => option.key"
            searchable no-options-text="暂无结果" placeholder="请输入姓名查找" :loading="companyInSerach">
            <template #appendInner>
              <va-icon name="manage_accounts"/>
            </template>
          </va-select>
        </div>
        <div class="flex md6 xs12">
          <va-select label="配合人员" outline
            :options="participantOptions" 
            @update-search="searchParticipants"
            v-model="record.participantsForm"
            clearable multiple
            :text-by="option => option.key"
            searchable no-options-text="暂无结果" placeholder="请输入姓名查找" :loading="companyInSerach">
            <template #appendInner>
              <va-icon name="groups"/>
            </template>
          </va-select>
        </div>
        <div class="flex md12 xs12">
          <picture-uploader class="px-2" :dragContainer="dragUploadContainer" ref="imgUploader" v-model:files="imgList" v-model:deletedFiles="deletedImgs" v-model:dirty="imgUploaderChanged"></picture-uploader>
        </div>
        <div class="flex md12 xs12">
          <va-input label="存在问题" v-model="record.potentialQuestions" :disabled="formReadonly" type="textarea" :max-rows="2"  outline />
        </div>
        <div class="flex md12 xs12">
          <va-input class="" label="进展情况" outline v-model="record.feedbackAfterContact" type="textarea" :min-rows="10" :max-rows="20"  :disabled="formReadonly" />
        </div>
      </div>
      <div class="mb-3">
        <va-button :rounded="false" class="px-2" @click="submit" v-if="!formReadonly">提交</va-button>
        <va-button :rounded="false" class="ml-3 px-2" @click="returnToList" outline flat>返回</va-button>
      </div>
    </va-form>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, onMounted, computed, ref, getCurrentInstance } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import axiosService from '@/services/AxiosService'
import moment from 'moment'
import { SelectOption } from '@/services/SelectOptionService'
import useSelectOptions from '@/common/CommonOptions'
import useUtility from '@/common/Utility'
import { Communication, CommunicationParticipants, FileItem, Document } from '@/model'
import { AxiosResponse } from 'axios'
import PictureUploader from '@/components/PictureUploader.vue';


export default defineComponent({
  components: {
    PictureUploader
  },
  setup() {
    const recordForm = ref<any>(null)
    const route = useRoute()
    const router = useRouter()
    const record = ref({ communicationParticipants: [] as Array<CommunicationParticipants> } as Communication)
    const formReadonly = computed(() => !!route.params.companyId && route.query.readonly == '1' )
    const dragUploadContainer = ref<HTMLDivElement>()
    const imgUploader = ref<null>(null)
    const imgList = ref<Array<FileItem>>([])
    const deletedImgs = ref<Array<Document>>([])
    const imgUploaderChanged = ref<boolean>(false)
    const { toast } = useUtility()

    /* target company */
    const companyOptions = reactive([] as Array<SelectOption>)
    const companyInSerach = ref(false)
    function searchCompany(keyword: string) {
      companyInSerach.value = true
      if(!keyword){
        companyInSerach.value = false
        return
      }
      axiosService.get(`odata/companys?$select=id,companyName&$top=20&$filter=contains(companyName,'${keyword}')`).then((res: AxiosResponse<{ value: Array<{companyName: string; id: number}>}>) => {
        const company = companyOptions.find(c => c.value == record.value.companyId)
        companyOptions.splice(0, companyOptions.length)
        Array.prototype.push.apply(companyOptions, res.data.value.map(company => {
          return {
            key: company.companyName,
            value: company.id
          }
        }))

        if(company && !companyOptions.find(c => c.value == company.value)) {
          companyOptions.push(company)
        }
        companyInSerach.value = false
      })
    }

    /* owner */
    const ownerOptions = reactive([] as Array<SelectOption>)
    function searchOwners(keyword: string)
    {
      if(!keyword){
        return
      }
      axiosService.get(`odata/users?$select=id,fullName&$top=20&$filter=contains(fullName,'${keyword}')`).then((res: AxiosResponse<{ value: Array<{fullName: string; id: number}>}>) => {
        const users = ownerOptions.filter(c => record.value.ownersForm && record.value.ownersForm.some(u => u.value == c.value))
        ownerOptions.splice(0, ownerOptions.length)
        Array.prototype.push.apply(ownerOptions, res.data.value.map(u => {
          return {
            key: u.fullName,
            value: u.id
          }
        }))

        users.forEach(u => {
          if(!ownerOptions.find(c => c.value == u.value)) {
            ownerOptions.push(u)
          }
        })
      })
    }

    /* participant */
    const participantOptions = reactive([] as Array<SelectOption>)
    function searchParticipants(keyword: string)
    {
      if(!keyword){
        return
      }
      axiosService.get(`odata/users?$select=id,fullName&$top=20&$filter=contains(fullName,'${keyword}')`).then((res: AxiosResponse<{ value: Array<{fullName: string; id: number}>}>) => {
        const users = participantOptions.filter(c => record.value.participantsForm && record.value.participantsForm.some(u => u.value == c.value))
        participantOptions.splice(0, participantOptions.length)
        Array.prototype.push.apply(participantOptions, res.data.value.map(u => {
          return {
            key: u.fullName,
            value: u.id
          }
        }))

        users.forEach(u => {
          if(!participantOptions.find(c => c.value == u.value)) {
            participantOptions.push(u)
          }
        })
      })
    }


    function saveDocuments(id: string): Promise<void> {
      const isUpdate = !!record.value.id
      if(isUpdate && 
        imgUploaderChanged.value && 
        (imgList.value.filter(img => !!img.id).length > 0 || deletedImgs.value.length > 0))
      {
        const params = new URLSearchParams();
        deletedImgs.value.forEach(img => {
          if(img.id) {
            params.append("documentIds", img.id)
          }
        });

        axiosService.delete("api/documents", {params: params}).then(res => {
          console.log('Removed following documents from database!')
          console.log(params)
        })
      }

      const newFiles = imgList.value.filter(img => !img.id);
      if(newFiles.length > 0) {
        newFiles.forEach(doc => {
          doc.regardingObjectType = 2 //communications
          doc.regardingObjecId = id
        })
        return axiosService.post('api/documents', newFiles)
      } else {
        return Promise.resolve()
      }
    }

    function formatDate(date: string) {
      if(date){
        return moment(date).format('yyyy/MM/DD')
      } else {
        return ''
      }
    }

    function returnToList()
    {
      router.push('/communication')
    }

    function submit() {
      record.value.communicationParticipants.splice(0,  record.value.communicationParticipants.length)
      // owners
      Array.prototype.push.apply(record.value.communicationParticipants, (record.value.ownersForm || []).map((u: SelectOption) => {
        return {
          id: u.id,
          participantId: u.value,
          regardingParticipantType: 1,
        }
      }))
      // participants
      Array.prototype.push.apply(record.value.communicationParticipants, (record.value.participantsForm || []).map((u: SelectOption) => {
        return {
          id: u.id,
          participantId: u.value,
          regardingParticipantType: 2,
        }
      }))
      
      if (recordForm.value.validate()) {
        Promise.resolve().then(() =>{
          if(record.value.communicateOn) {
              record.value.communicateOn = moment(record.value.communicateOn).format('yyyy-MM-DD')
          }

          if(!record.value.id)
          {
            return axiosService.post('api/communications', record.value)
              .then(res => {
                return res.data.id
              })
          }
          else
          {
            return axiosService.patch(`api/communications/${record.value.id}`, record.value)
              .then(res => {
                return record.value.id
              })
          }
        }).then((id: string) => {
          return saveDocuments(id)
        }).then(() => {
          if(!record.value.id)
          {
            toast('添加项目跟进记录成功！')
          } else {
            toast('修改项目跟进记录成功！')
          }
          router.push({ path: '/communication' })
        })
      }
    }

    onMounted(() => {
      if (route.params.communicationId) {
        axiosService.get(`odata/communications(${route.params.communicationId})?$expand=company($select=companyName),CommunicationParticipants($select=id,participantId,regardingParticipantType;$expand=Participant($select=id,fullName))`)
          .then((res: AxiosResponse<Communication>) => {
            if (res.data) {
              record.value = res.data
              record.value.communicationType = (parseInt(res.data.communicationType ?? '') ?? null) as any // weird style, need change.
              record.value.communicateOn = moment(res.data.communicateOn).toDate() as any
              companyOptions.push({key: res.data.company.companyName, value: res.data.companyId} as SelectOption)
              record.value.ownersForm = res.data.communicationParticipants.filter(p => p.regardingParticipantType == 1).map(p => {
                return {
                  id: p.id,
                  key: p.participant.fullName,
                  value: p.participantId
                }
              })

              record.value.participantsForm = res.data.communicationParticipants.filter(p => p.regardingParticipantType == 2).map(p => {
                return {
                  id: p.id,
                  key: p.participant.fullName,
                  value: p.participantId
                }
              })
            }
          })

          // get documents
        axiosService.get(`api/communications/${route.params.communicationId}/documents`).then(res => {
          if (res.data) {
            res.data.forEach((d: Document) => {
              imgList.value.push({
                  id: d.id,
                  regardingObjectType: d.regardingObjectType,
                  regardingObjecId: d.regardingObjecId,
                  fileSize: d.fileSize,
                  fileName: d.fileName,
                  mime: d.mime,
                  fileContentBase64: d.fileContentBase64,
                  fileContent: d.fileContent,
                  key: Math.random().toString(32).substring(2)
              } as FileItem)
            })
          }
        })
      }
    })

    return {
      record,
      ...useSelectOptions(),
      companyOptions,
      companyInSerach,
      formatDate,
      recordForm,
      formReadonly,
      submit,
      returnToList,
      searchCompany,
      ownerOptions,
      searchOwners,
      participantOptions,
      searchParticipants,
      imgUploader,
      imgList,
      deletedImgs,
      imgUploaderChanged,
      dragUploadContainer,
    }
  }
})
</script>

<style lang="scss" scoped>
.company {
  background-color: #fff;
}
</style>
